export class FileType {
  extension: string;
  mimeType: string;
}

export const filterFileListForFileType = (
  fileList: FileList,
  acceptedFileTypes: FileType[],
): File[] =>
  Array.from(fileList).filter((file) =>
    acceptedFileTypes.map((fileType) => fileType.mimeType).includes(file.type),
  );

export const getAcceptedFileExtensions = (
  acceptedFileTypes: FileType[],
): string => acceptedFileTypes.map((fileType) => fileType.extension).toString();
